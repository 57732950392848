<script>
  /**
   * A phone number input.
   */
  export default {
    props: {
      /** The input’s value. */
      value: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        phoneNumberMask: [
          {
            mask: '{+1} (000) 000 - 0000',
            lazy: false,
          },
        ],
      }
    },
  }
</script>

<template>
  <BaseInput
    type="tel"
    :mask="phoneNumberMask"
    :min-length="12"
    :value-is-unmasked="true"
    :custom-messages="{ min: 'Please enter a valid phone number.' }"
    has-placeholder
    v-bind="{ ...$props, ...$attrs }"
    v-on="$listeners"
  >
    <!-- @slot The input’s label. Should contain text. Defaults to “Phone Number”. -->
    <slot>Phone Number</slot>
    <template v-slot:icons>
      <!-- @slot Optional. Icon(s) to display on the right end of the input. Should contain one or multiple `BaseIconInput` component(s). -->
      <slot name="icons" />
    </template>
  </BaseInput>
</template>
